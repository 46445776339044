import NewsItem from './NewsItem'
import InfiniteScroll from "react-infinite-scroll-component";




import React, { useEffect, useState } from 'react'

const News = (props) => {


    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    document.title = `DailyNews - ${capitalizeFirstLetter(props.catagory)}`

    const [articles, setarticles] = useState([]);
    const [page, setpage] = useState(1);
    const [totalResults, settotalResults] = useState(0);



    const updateNews = async () => {
        props.setProgress(0);
        let url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.catagory}&apiKey=${props.apiKey}&page=${page}&pageSize=${props.pageSize}`;
        let data = await fetch(url);
        let parsedData = await data.json();
        setarticles(parsedData.articles)
        settotalResults(parsedData.totalResults)

        props.setProgress(100);
    }
    useEffect(() => {
        updateNews();
    }, /* eslint-disable */[]);

    const fetchMoreData = async () => {
        setpage(page + 1)
        let url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.catagory}&apiKey=${props.apiKey}&page=${page + 1}&pageSize=${props.pageSize}`;
        let data = await fetch(url);
        let parsedData = await data.json();
        setarticles(articles.concat(parsedData.articles))
        settotalResults(parsedData.totalResults)


    };


    return (
        <>
            <h1 className='text-center' style={{ marginTop: '90px' }}>DailyNews - Top {capitalizeFirstLetter(props.catagory)} Headlines </h1>
            <InfiniteScroll
                dataLength={articles.length}
                next={fetchMoreData}
                hasMore={articles.length !== totalResults}
            >
                <div className="container"><div className="row">
                    {articles.map((element) => {
                        return <div className="col-md-4 my-4" key={element.url}>
                            <NewsItem title={element.title} description={element.description} imgUrl={element.urlToImage} newsUrl={element.url} author={element.author} date={element.publishedAt} source={element.source.name} />
                        </div>
                    })}
                </div>
                </div>

            </InfiniteScroll>

        </>
    )
}

News.defaultProps = {
    country: "us",
    pageSize: 15,
    catagory: 'general'
}

export default News
